



















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'

@Component
class SectionLarge extends Vue {
  @Prop({ default: () => ({ sectionMainTitle: '' }) }) readonly sectionData:
    | Object
    | undefined

  @Prop() readonly cardSize: String = ''
  @Prop() readonly isExtended: Boolean = false
  static options: any
}

const SectionLargeProps = SectionLarge.options.props

export default defineComponent({
  name: 'SectionLarge',
  props: { ...SectionLargeProps },
  setup() {},
})
