




































import {
  defineComponent,
  ref,
  onMounted,
  useRoute,
  useStore,
  onUnmounted,
} from '@nuxtjs/composition-api'
import queryString from 'query-string'
import { Logger } from '@/composition/Logger'
import {
  emptyPlugData,
  emptyPromoCode,
  IPromoBanner,
  IPromoCode,
  ISectionIframe,
  PULSE_DOMAIN,
} from '@/contracts/ISectionIframe'
import { defineProps } from '@/helpers/defineProps'
import { emptySectionData } from '@/contracts/SectionData'
import SectionLarge from '@/components/SectionLarge.vue'
import BlockBannerText from '@/components/BlockBannerText.vue'
import { getColorTitle } from '@/composition/coloredTitle'

export default defineComponent({
  name: 'SectionIframe',
  components: {
    SectionLarge,
    BlockBannerText,
  },
  props: defineProps<ISectionIframe>({
    sectionData: {
      default: emptySectionData(),
    },
    iframeData: {
      default: '',
    },
    plugData: {
      default: emptyPlugData(),
    },
    promoCodes: {
      default: emptyPromoCode(),
    },
    clearPage: {
      default: false,
    },
  }),
  setup(props, ctx) {
    const getActive = (activeFrom: string, activeTo: string) => {
      const planDateOn = activeFrom && new Date(activeFrom)
      const planDateOff = activeTo && new Date(activeTo)
      const today = new Date()

      if (planDateOn && planDateOff) {
        return planDateOn < today && today < planDateOff
      } else if (planDateOn) {
        return planDateOn < today
      } else if (planDateOff) {
        return today < planDateOff
      }
      return false
    }

    const isPlugOn = ref(false)
    const promoBanner = ref<IPromoBanner>()
    // промокод берем из урла ?promo={символьный код промокода}
    const route = useRoute()
    const banner: IPromoCode = props.promoCodes.find(
      (item: IPromoCode) => item.code === route.value.query.promo
    )
    const store = useStore()

    onMounted(() => {
      if (process.browser) {
        isPlugOn.value = getActive(
          props.plugData.plugDateOn,
          props.plugData.plugDateOff
        )
        if (banner) {
          const { startDatePromo, endDatePromo, activePromo } = banner
          promoBanner.value = {
            ...banner,
            isActive: activePromo
              ? endDatePromo || startDatePromo
                ? getActive(startDatePromo, endDatePromo)
                : true
              : false,
          }
        }
      }

      const el = ctx.refs.iframe as HTMLDivElement
      if (el) {
        el.innerHTML = props.iframeData
        const query = queryString.stringify(route.value.query)
        const iframe = el.getElementsByTagName('iframe')[0]
        if (iframe) {
          store.commit('settings/PUSH_IFRAME_SRC', iframe.src)
        }
        if (iframe && iframe.src.includes(PULSE_DOMAIN)) {
          const splitUrl = iframe.src.split('#')
          iframe.src = `${splitUrl[0]}${
            query && (iframe.src.includes('?') ? '&' : '?')
          }${query}${splitUrl[1] ? `#${splitUrl[1]}` : ''}`
        }

        Array.from(el.querySelectorAll('script')).forEach((script) => {
          if (script.src) {
            const recaptchaScript = document.createElement('script')
            recaptchaScript.src = script.src
            for (let i = 0; i < script.attributes.length; i++) {
              recaptchaScript.setAttribute(
                script.attributes[i].name,
                script.attributes[i].value
              )
            }
            el.appendChild(recaptchaScript)
            script.remove()
          } else if (script.innerHTML) {
            try {
              // eslint-disable-next-line no-eval
              eval(script.innerHTML)
            } catch (err) {
              Logger.error(err, 'IFrame loading error')
            }
          }
        })
      }
    })
    onUnmounted(() => {
      if (props.clearPage) {
        store.commit('settings/SET_HIDE_FOOTER', false)
        store.commit('settings/SET_HIDE_HEADER', false)
        store.commit('settings/SET_HIDE_SURVEY_BTN', false)
      }
      store.commit('settings/SET_IFRAME_SRC', [])
    })
    return { isPlugOn, promoBanner, getColorTitle }
  },
})
